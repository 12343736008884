<template>
    <div class="sup_footer p-d-flex p-jc-between p-ai-center">
        <div class="sup_copyright_info">
            <strong>© Copyright 2021 <a href="https://console.staging.truabilities.com/">TruAbilities</a>.</strong> All rights reserved.
        </div>
        <div class="sup_portal_info">
            <strong>TruAbilities</strong> Admin System | Version 2.0
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/component/superAdmin/common/Footer.scss";
</style>